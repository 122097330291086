import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ServiceBlog = ({ blogData }) => {
  return (
    <CardContainer>
      {blogData.map((item) => (
        <Container>
          <TopSection
            image={item.image.childImageSharp.resize.src}
          ></TopSection>
          <BottomSection>
            <BlogTitle>{item.title}</BlogTitle>
            <BlogText>{item.text}</BlogText>
          </BottomSection>
          <LearnMoreContainer>
            <LearnMoreLink>Learn More</LearnMoreLink>
          </LearnMoreContainer>
        </Container>
      ))}
    </CardContainer>
  );
};

const Container = styled.div`
  width: 49%;
  margin-bottom: 5rem;
  background: #fff;
  padding-bottom: 50px;
  @media (max-width: 970px) {
    width: 100%;
    height: 100%;
  }
`;

const TopSection = styled.div`
  height: 350px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 970px) {
  }
`;

const BottomSection = styled.div`
  padding: 4rem 4rem 8rem;
  background: #fff;
  height: 350px;
  display: flex;
  flex-direction: column;
  @media (max-width: 970px) {
    height: 450px;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 4rem;
  }
`;
const CardContainer = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 970px) {
    flex-direction: column;
    align-content: center;
    width: 100%;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const BlogTitle = styled.p`
  font-family: 'Archivo';
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
  text-transform: uppercase;
  color: #191919;
  margin-bottom: 0.875rem;
  @media (max-width: 970px) {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    box-pack: justify;
    font-weight: 800;
    line-height: 1.3;
  }
`;

const BlogText = styled.p`
  font-family: 'Archivo';
  font-weight: 400;
  text-align: justify;
  font-size: 1rem;
  color: #191919;
  margin-bottom: 2rem;
`;

const LearnMoreLink = styled(Link)`
  color: #0066b2;
  &:hover {
    color: #fff;
  }
`;
const LearnMoreContainer = styled.div`
  width: 243px;
  height: 62px;
  margin-left: 70px;
  opacity: 0.7;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 1px solid #0066b2;
  &:hover {
    opacity: 1;
    background: #0066b2;
    color: #fff;
  }
`;

export default ServiceBlog;
