import React from "react";
import styled from "styled-components";
import ModalButton from "../Modal/Modal";
// import { StaticImage } from "gatsby-plugin-image";

const TopSection = ({ topData }) => {
  return (
    <div>
      {topData.map((item) => (
        <TopSectionContainer background={item.image.childImageSharp.resize.src}>
          <div style={{ display: "flex", margin: "auto" }}>
            <Content>
              <Title>{item.title}</Title>
              <SubTitle>{item.text}</SubTitle>
              <ButtonContainer>
                <ModalButton
                  buttonText={"Get our Expert consultation"}
                ></ModalButton>
              </ButtonContainer>
            </Content>
          </div>
        </TopSectionContainer>
      ))}
    </div>
  );
};

const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  font-weight: 750;
  line-height: 1.05;
  max-width: 1024px;
  padding-bottom: 0;
  margin: 0 auto 2rem;
`;

const TopSectionContainer = styled.div`
  width: 100%;
  background: #191919;
  color: #fff;
  background: linear-gradient(rgba(2, 0, 36, 10), rgba(200, 70, 30, 0.4)),
    url(${(props) => props.background}); /* The least supported option. */
  background-repeat: no-repeat;
  background-position: center;
  padding-top: auto;
  padding-bottom: auto;
  height: 650px;
  position: relative;
  background-size: cover;
  display: flex;
  @media (max-width: 970px) {
  }
`;
const SubTitle = styled.p`
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-family: 'Archivo';
  font-weight: 500;
  line-height: 1.25;
  text-align: center;
  font-weight: 400;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 13px;
`;
const Content = styled.div`
  max-width: 840px;
  margin: auto;
  padding-bottom: 0;
  @media (max-width: 970px) {
    padding-top: 90px;
  }
`;

const ButtonContainer = styled.p`
  margin-top: 4rem;
  margin-left: 15rem;
  margin-right: 2rem;
  text-align: center;
  @media (max-width: 970px) {
    margin-left: 10rem;
  }
  @media (max-width: 650px) {
    margin-left: 7rem;
  }
  @media (max-width: 420px) {
    margin-left: 3rem;
  }
`;

export default TopSection;
