import React from 'react'
import Layout from '../../components/Layout';
import ServiceIndex from  '../../components/Service/ServiceIndex/ServiceIndex';

const index = () => {
    return (
        <Layout>
            <ServiceIndex/>

        </Layout>
    )
}

export default index
